import { useQuery } from "@tanstack/react-query";
import { Alert, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { fetchProjects } from "../api";

function ProjectSelect({
  projectId,
  onChange,
}: {
  projectId: string | null;
  onChange: (projectId: string) => void;
}) {
  const { isPending, isError, data, error } = useQuery({
    queryKey: ["projects"],
    queryFn: () => fetchProjects(),
  });

  if (isPending) {
    return <Spinner size="sm" />;
  } else if (isError) {
    return (
      <Alert variant="warning">Could not load projects: {error.message}.</Alert>
    );
  } else {
    return (
      <Form.Select
        value={projectId || ""}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="">All projects</option>
        <option value="default">Default</option>

        {data.items.map((project) => {
          return (
            <option key={project.id} value={project.id}>
              {project.name}
            </option>
          );
        })}
      </Form.Select>
    );
  }
}

export default ProjectSelect;

import { useState } from "react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Alert, Button, Form, Spinner, Table } from "react-bootstrap";

import type { Page } from "../api/types";
import { getAPI, postAPI } from "../api";

const agentTemplateQueries = {
  all: () => ["agent_templates"],
};

type AgentTemplate = {
  id: string;
  template_name: string;
};

function fetchAgentTemplates(): Promise<Page<AgentTemplate>> {
  return getAPI("/api/agent-templates");
}

type NewAgentTemplate = {
  template_name: string;
};

function createAgentTemplate(
  newTemplate: NewAgentTemplate,
): Promise<AgentTemplate> {
  return postAPI(`/api/agent-templates`, newTemplate);
}

function NewAgentTemplateRow() {
  const [name, setName] = useState<string>("");

  const queryClient = useQueryClient();

  const createTemplate = useMutation({
    mutationFn: createAgentTemplate,
    onSuccess: (tmpl) => {
      setName("");

      queryClient.setQueryData<Page<AgentTemplate>>(
        agentTemplateQueries.all(),
        (agentTemplates) =>
          agentTemplates && {
            ...agentTemplates,
            items: [...agentTemplates.items, tmpl],
          },
      );
    },
  });

  return (
    <tr>
      <td />
      <td>
        <Form.Control
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="name"
        />
      </td>

      <td>
        <Button
          variant="light"
          onClick={() => createTemplate.mutate({ template_name: name })}
          disabled={createTemplate.isPending || !name}
        >
          {createTemplate.isPending ? <Spinner size="sm" /> : "➕"}
        </Button>
      </td>
    </tr>
  );
}

function AgentsPage() {
  const {
    isPending,
    isError,
    data: agentTemplates,
    error,
  } = useQuery({
    queryKey: agentTemplateQueries.all(),
    queryFn: fetchAgentTemplates,
  });

  if (isPending) {
    return <div>Loading...</div>;
  } else if (isError) {
    return (
      <Alert variant="warning">Could not load projects: {error.message}.</Alert>
    );
  } else {
    return (
      <>
        <h1>Agent Templates</h1>

        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {agentTemplates.items.map((tmpl) => {
              return (
                <tr key={tmpl.id}>
                  <td>
                    <code>{tmpl.id}</code>
                  </td>
                  <td>{tmpl.template_name}</td>
                </tr>
              );
            })}
            <NewAgentTemplateRow />
          </tbody>
        </Table>
      </>
    );
  }
}

export default AgentsPage;

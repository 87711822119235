import { useRef, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { Alert, Button, ButtonGroup, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { getAPI } from "../api";
import { formatDateTime } from "../utils/date-time";

const eventStreamQueries = {
  get: (sessionId: string, agentId: string) => [
    "event_streams",
    "get",
    { sessionId, agentId },
  ],
};

type Source = "system" | "user" | "assistant" | "function";

type Message =
  | {
      source: Source;
      type: "ContentEventMessage";
      kwargs: { content: string };
    }
  | {
      source: Source;
      type: "FnCallEventMessage";
      kwargs: { function_call: { name: string; args: Record<string, any> } };
    }
  | {
      source: Source;
      type: "FactEventMessage";
      kwargs: { fact_name: string; content: string };
    };

type Event = {
  id: string;
  event_type: string;
  message: Message;
};

type Moment = {
  id: number;
  created: number;
  events: Array<Event>;
};

type EventStream = { moments: Array<Moment> };

function fetchEventStream(
  sessionId: string,
  agentId: string,
): Promise<EventStream> {
  return getAPI(`/api/sessions/${sessionId}/agent/${agentId}/event-stream`);
}

function DisplaySource({ source }: { source: Source }) {
  if (source === "user") {
    return <i className="bi-controller" />;
  } else if (source === "assistant") {
    return <i className="bi-robot" />;
  } else if (source === "system") {
    return <i className="bi-braces" />;
  } else if (source === "function") {
    return <i className="bi-exclamation" />;
  }
  source satisfies never;
}

function FunctionCall({
  fn,
}: {
  fn: { name: string; args: Record<string, any> };
}) {
  return (
    <code style={{ whiteSpace: "pre-wrap" }}>
      {fn.name}(
      {Object.keys(fn.args)
        .map((argName, _i) => {
          const prettyValue = JSON.stringify(fn.args[argName], null, 2);
          return `${argName}=${prettyValue}`;
        })
        .join(", ")}
      )
    </code>
  );
}

function Fact({ kwargs }: { kwargs: { fact_name: string; content: string } }) {
  return (
    <>
      Fact <code>{kwargs.fact_name}</code>
      <br />
      {kwargs.content}
    </>
  );
}

function EventRow({ event }: { event: Event }) {
  const message = event.message;

  let content: React.ReactElement | string;
  if (message.type === "ContentEventMessage") {
    content = message.kwargs.content;
  } else if (message.type === "FnCallEventMessage") {
    content = <FunctionCall fn={message.kwargs.function_call} />;
  } else if (message.type === "FactEventMessage") {
    content = <Fact kwargs={message.kwargs} />;
  } else {
    content = "(unrecognized message)";
  }

  return (
    <tr
      key={event.id}
      style={{ background: "#eee", border: "4px solid white" }}
    >
      <td align="right" valign="top" className="pe-2">
        <DisplaySource source={event.message.source} />
      </td>
      <td>{content}</td>
    </tr>
  );
}

function DisplayMoment({ moment }: { moment: Moment }) {
  return (
    <>
      <tr>
        <td colSpan={2} id={`moment-${moment.id}`}>
          <b>Moment {moment.id}</b> ({formatDateTime(moment.created)})
        </td>
      </tr>
      {moment.events.map((event) => {
        return <EventRow event={event} key={event.id} />;
      })}
    </>
  );
}

function DisplayEventStream({
  eventStream,
  refetch,
  isRefetching,
}: {
  eventStream: EventStream;
  refetch: () => void;
  isRefetching: boolean;
}) {
  const startOfStream = useRef<null | HTMLDivElement>(null);
  const endOfStream = useRef<null | HTMLDivElement>(null);

  let currentMomentIndex: number = eventStream.moments.length;

  const scrollToTop = () => {
    startOfStream.current?.scrollIntoView({ behavior: "smooth" });
    currentMomentIndex = 0;
  };

  const scrollToBottom = () => {
    endOfStream.current?.scrollIntoView({ behavior: "smooth" });
    currentMomentIndex = eventStream.moments.length - 1;
  };

  const scrollToMoment = (momentId: number) => {
    window.document
      .getElementById(`moment-${momentId}`)
      ?.scrollIntoView({ behavior: "smooth" });
  };

  // when the component loads new data, scroll to the bottom.
  const [prevMoments, setPrevMoments] = useState(eventStream.moments);
  if (eventStream.moments !== prevMoments) {
    setPrevMoments(eventStream.moments);
    scrollToBottom();
  }

  const showPrevMoment = () => {
    if (currentMomentIndex <= 0) return;

    currentMomentIndex -= 1;
    scrollToMoment(eventStream.moments[currentMomentIndex].id);
  };

  const showNextMoment = () => {
    if (currentMomentIndex >= eventStream.moments.length - 1) return;

    currentMomentIndex += 1;
    scrollToMoment(eventStream.moments[currentMomentIndex].id);
  };

  // sorry about the horrible sticky toolbar :(
  return (
    <>
      <div ref={startOfStream} />

      <div className="sticky-top" style={{ textAlign: "right" }}>
        <ButtonGroup>
          <Button
            className="bi-chevron-bar-up"
            variant="secondary"
            onClick={() => scrollToTop()}
          />{" "}
          <Button
            className="bi-chevron-up"
            variant="secondary"
            onClick={() => showPrevMoment()}
          />{" "}
          <Button
            className="bi-chevron-down"
            variant="secondary"
            onClick={() => showNextMoment()}
          />{" "}
          <Button
            className="bi-chevron-bar-down"
            variant="secondary"
            onClick={() => scrollToBottom()}
          />{" "}
          {!isRefetching && (
            <Button
              className="bi-arrow-clockwise"
              variant="secondary"
              onClick={() => refetch()}
            />
          )}
          {isRefetching && (
            <Button variant="secondary" disabled>
              <Spinner size="sm" />
            </Button>
          )}
        </ButtonGroup>
      </div>

      <table style={{ width: "100%" }}>
        <tbody>
          {eventStream.moments.map((moment) => {
            return <DisplayMoment moment={moment} key={moment.id} />;
          })}
        </tbody>
      </table>

      <div ref={endOfStream} />
    </>
  );
}

function AgentEventStream() {
  const { sessionId, agentId } = useParams();

  const {
    isPending,
    isError,
    data: eventStream,
    error,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: eventStreamQueries.get(sessionId!, agentId!),
    queryFn: () => fetchEventStream(sessionId!, agentId!),
  });

  let result: React.ReactElement;
  if (isPending) {
    result = <div>Loading...</div>;
  } else if (isError) {
    result = (
      <Alert variant="warning">
        Failed to load event stream: {error.message}
      </Alert>
    );
  } else {
    result = (
      <DisplayEventStream
        eventStream={eventStream}
        refetch={refetch}
        isRefetching={isRefetching}
      />
    );
  }

  return (
    <>
      <h3>Event stream</h3>

      {result}
    </>
  );
}

export default AgentEventStream;

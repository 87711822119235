import { Card, Col, Row } from "react-bootstrap";
import { Link, useOutletContext } from "react-router-dom";

import type { SessionDetails } from "../api/types";
import { RawMetadata } from "./utils";

function SessionAgents() {
  const { session }: { session: SessionDetails } = useOutletContext();

  return (
    <>
      <h3>Agents</h3>

      <p>These are the agents that exist within this session.</p>

      <Row xs={1} md={2}>
        {session.agents.map((agent) => {
          return (
            <Col key={agent.id}>
              <Card>
                <Card.Body>
                  <Card.Title>{agent.id}</Card.Title>

                  <RawMetadata metadata={agent.metadata} />

                  <Link
                    to={`/sessions/${session.id}/api-requests?agent_id=${agent.id}`}
                    className="card-link"
                  >
                    API Requests
                  </Link>

                  <Link
                    to={`/sessions/${session.id}/agent/${agent.id}/event-stream`}
                    className="card-link"
                  >
                    Event Stream
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  );
}

export default SessionAgents;

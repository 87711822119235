import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

function Sidebar() {
  return (
    <div className="sidebar border border-right col-md-3 col-lg-2 p-0 bg-body-tertiary">
      <Nav className="flex-column">
        <Nav.Item>
          <Link to="/projects" className="nav-link">
            <i className="bi-motherboard" /> Projects
          </Link>
          <Link to="/sessions" className="nav-link">
            <i className="bi-clock-history" /> Sessions
          </Link>
          <Link to="/agents" className="nav-link">
            <i className="bi-robot" /> Agents
          </Link>
          <Link to="/api-requests" className="nav-link">
            <i className="bi-arrow-left-right" /> API Requests
          </Link>
          <Nav.Link disabled>
            <i className="bi-bar-chart" /> Usage
          </Nav.Link>
          <Link to="/users" className="nav-link">
            <i className="bi-people" /> Users
          </Link>
          <Link to="/api-keys" className="nav-link">
            <i className="bi-braces" /> API keys
          </Link>
        </Nav.Item>
      </Nav>
    </div>
  );
}

export default Sidebar;

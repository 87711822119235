export function RawMetadata({
  metadata,
}: {
  metadata: Record<string, string>;
}) {
  return (
    <table>
      <tbody>
        {Object.keys(metadata).map((k) => {
          return (
            <tr key={k}>
              <th className="pe-2">{k}</th>
              <td>{metadata[k]}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

import { useState } from "react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Alert, Button, Form, Spinner, Table } from "react-bootstrap";

import type { Page, User } from "../api/types";
import { fetchUsers, postAPI } from "../api";

const userQueries = {
  all: () => ["users"],
};

type NewUser = {
  email: string;
};

function NewUserRow() {
  const [email, setEmail] = useState<string>("");

  const queryClient = useQueryClient();
  const createUser = useMutation({
    mutationFn: (newUser: NewUser) => postAPI(`/api/users`, newUser),
    onSuccess: (u) => {
      setEmail("");

      queryClient.setQueryData<Page<User>>(
        userQueries.all(),
        (users) =>
          users && {
            ...users,
            items: [...users!.items, u],
          },
      );
    },
  });

  return (
    <tr>
      <td>
        <Form.Control
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="email"
        />
      </td>

      <td>
        <Button
          variant="light"
          onClick={() => createUser.mutate({ email })}
          disabled={createUser.isPending || !email}
        >
          {createUser.isPending ? <Spinner size="sm" /> : "➕"}
        </Button>
      </td>
    </tr>
  );
}

function UsersPage() {
  const {
    isPending,
    isError,
    data: users,
    error,
  } = useQuery({
    queryKey: userQueries.all(),
    queryFn: fetchUsers,
  });

  if (isPending) {
    return <div>Loading...</div>;
  } else if (isError) {
    return (
      <Alert variant="warning">Could not load users: {error.message}.</Alert>
    );
  } else {
    return (
      <>
        <h2>Users</h2>

        <p>
          Users are accounts that are allowed to access this organization's
          dashboard.
        </p>

        <Table>
          <thead>
            <tr>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {users.items.map((u) => {
              return (
                <tr key={u.id}>
                  <td>{u.email}</td>
                </tr>
              );
            })}
            <NewUserRow />
          </tbody>
        </Table>
      </>
    );
  }
}

export default UsersPage;

import { useQuery } from "@tanstack/react-query";
import { Alert, Table } from "react-bootstrap";

import { fetchOrganization } from "../api";

function OrganizationPage() {
  const {
    isPending,
    isError,
    data: organization,
    error,
  } = useQuery({ queryKey: ["organization"], queryFn: fetchOrganization });

  if (isPending) {
    return <div>Loading...</div>;
  } else if (isError) {
    return (
      <Alert variant="warning">
        Could not load organization: {error.message}.
      </Alert>
    );
  } else {
    return (
      <Table>
        <tbody>
          <tr>
            <th>Short Name</th>
            <td>{organization.short_name}</td>
          </tr>
          <tr>
            <th>Name</th>
            <td>{organization.name}</td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default OrganizationPage;

import { useEffect, useState } from "react";

import { Accordion, Alert, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";

import type { APIRequest } from "../api/types";
import { fetchAPIRequest } from "../api";
import { formatDateTime } from "../utils/date-time";
import { StatusBadge } from "./utils";

function DumpJSON({ body }: { body: string | null }) {
  if (body === null) {
    return <p>No body.</p>;
  }

  let formattedBody = null;
  try {
    const parsedBody = JSON.parse(body);
    formattedBody = JSON.stringify(parsedBody, null, 2);
  } catch {
    formattedBody = body;
  }

  return <pre style={{ whiteSpace: "pre-wrap" }}>{formattedBody}</pre>;
}

function RequestMetadata({ apiRequest }: { apiRequest: APIRequest }) {
  return (
    <Table>
      <tbody>
        <tr>
          <th>Received at</th>
          <td>{formatDateTime(apiRequest.created)}</td>
        </tr>
        <tr>
          <th>Request path</th>
          <td>
            <code>{apiRequest.request.path}</code>
          </td>
        </tr>
        <tr>
          <th>Response status</th>
          <td>
            <StatusBadge statusCode={apiRequest.response.status_code} />
          </td>
        </tr>
        <tr>
          <th>Project</th>
          <td>{apiRequest.project_name}</td>
        </tr>
        {apiRequest.session_id && (
          <tr>
            <th>Session</th>
            <td>{apiRequest.session_id}</td>
          </tr>
        )}
        {apiRequest.agent_id && (
          <tr>
            <th>Agent</th>
            <td>{apiRequest.agent_id}</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

function APIRequestPage() {
  const { apiRequestId } = useParams();

  const [loading, setLoading] = useState(false);
  const [apiRequest, setAPIRequest] = useState<APIRequest | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);

    fetchAPIRequest(apiRequestId!)
      .then((r) => setAPIRequest(r))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [apiRequestId]);

  if (loading) {
    return <div>Loading...</div>;
  } else if (!apiRequest) {
    return (
      <Alert variant="warning">
        Could not load API request: {error?.message}.
      </Alert>
    );
  } else {
    return (
      <>
        <h2>API request</h2>

        <RequestMetadata apiRequest={apiRequest} />

        <Accordion
          defaultActiveKey={["request", "response"]}
          alwaysOpen
          className="mt-4"
        >
          <Accordion.Item eventKey="request">
            <Accordion.Header>Request</Accordion.Header>

            <Accordion.Body>
              <DumpJSON body={apiRequest.request.body} />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="response">
            <Accordion.Header>Response</Accordion.Header>

            <Accordion.Body>
              <DumpJSON body={apiRequest.response.body} />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </>
    );
  }
}

export default APIRequestPage;

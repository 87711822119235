import { useQuery } from "@tanstack/react-query";
import { Alert, Nav, Spinner, Table } from "react-bootstrap";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";

import type { SessionDetails } from "../api/types";
import { fetchSession } from "../api";
import { formatDateTime } from "../utils/date-time";
import { RawMetadata } from "./utils";

function SessionMetadata({ session }: { session: SessionDetails }) {
  return (
    <Table>
      <tbody>
        <tr>
          <th>Created</th>
          <td>{formatDateTime(session.created)}</td>
        </tr>
        <tr>
          <th>Project</th>
          <td>{session.project_name}</td>
        </tr>
        <tr>
          <th>Session Metadata</th>
          <td>
            <RawMetadata metadata={session.metadata} />
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

function SessionPage() {
  const { sessionId } = useParams();
  const { pathname } = useLocation();

  const { isPending, isError, data, error } = useQuery({
    queryKey: ["session", sessionId!],
    queryFn: () => fetchSession(sessionId!),
  });

  if (isPending) {
    return <Spinner />;
  } else if (isError) {
    return (
      <Alert variant="warning">
        Could not load API request: {error.message}.
      </Alert>
    );
  } else {
    return (
      <>
        <h2>Session</h2>

        <SessionMetadata session={data} />

        <Nav variant="tabs" className="mb-2" activeKey={pathname}>
          <Nav.Item>
            <Nav.Link
              as={Link}
              // duplicating to & href is a hack to make the correct tab display active
              to={`/sessions/${data.id}`}
              href={`/sessions/${data.id}`}
              className="nav-link"
            >
              Agents
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              as={Link}
              // duplicating to & href is a hack to make the correct tab display active
              to={`/sessions/${data.id}/api-requests`}
              href={`/sessions/${data.id}/api-requests`}
              className="nav-link"
            >
              API Requests
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Outlet context={{ session: data }} />
      </>
    );
  }
}

export default SessionPage;

import SearchAPIRequests from "./SearchAPIRequests";

function APIRequestsPage() {
  return (
    <>
      <h2>API requests</h2>
      <SearchAPIRequests />
    </>
  );
}

export default APIRequestsPage;

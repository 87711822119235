import { CredentialResponse, GoogleLogin } from "@react-oauth/google";

import { CurrentSession } from "./types";

function Auth({ onSuccess }: { onSuccess: (whoami: CurrentSession) => void }) {
  const handleSuccess = (response: CredentialResponse) => {
    fetch("/api/auth/credentials", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(response),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then((whoami) => onSuccess(whoami))
      .catch((err) => console.log(err));
  };

  const handleError = () => {
    console.log("Google login failed.");
  };

  return <GoogleLogin onSuccess={handleSuccess} onError={handleError} />;
}

export default Auth;

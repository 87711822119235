import { Badge } from "react-bootstrap";

export function StatusBadge({ statusCode }: { statusCode: number }) {
  const isSuccess = statusCode >= 200 && statusCode < 300;

  const [bg, text] = isSuccess ? ["secondary", undefined] : ["warning", "dark"];

  return (
    <Badge bg={bg} text={text}>
      {statusCode}
    </Badge>
  );
}

import { useMemo, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Row,
  Spinner,
  Table,
  ToggleButton,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";

import type { APIRequestSummary, Page } from "../api/types";
import { fetchAPIRequests } from "../api";
import ProjectSelect from "../projects/ProjectSelect";
import { PaginatedSearch, Paginator } from "../utils/pagination";
import { StatusBadge } from "./utils";

type SearchFilters = {
  showResults: "all" | "only-errors";
  projectId: string | null;
  agentId?: string;
  sessionId?: string;
};

const apiRequestQueries = {
  search: (filters: PaginatedSearch<SearchFilters>) => [
    "api_requests",
    "search",
    filters,
  ],
};

function APIRequestRow({
  apiRequest,
  showProject,
}: {
  apiRequest: APIRequestSummary;
  showProject: boolean;
}) {
  return (
    <tr>
      <td>
        <Link to={`/api-requests/${apiRequest.id}`}>
          <ReactTimeAgo
            date={new Date(apiRequest.created * 1000)}
            timeStyle="round"
          />
        </Link>
      </td>
      {showProject && <td>{apiRequest.project_name}</td>}
      <td>
        <code>{apiRequest.req_method}</code>
      </td>
      <td>
        <code>{apiRequest.req_path}</code>
      </td>
      <td>
        <StatusBadge statusCode={apiRequest.res_status_code} />
      </td>
    </tr>
  );
}

function APIRequestsTable({
  apiRequests,
  showProject,
}: {
  apiRequests: Page<APIRequestSummary>;
  showProject: boolean;
}) {
  if (apiRequests.items.length === 0) {
    return <Alert>No matching API requests.</Alert>;
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Time</th>
          {showProject && <th>Project</th>}
          <th>Method</th>
          <th>Path</th>
          <th>Response</th>
        </tr>
      </thead>
      <tbody>
        {apiRequests.items.map((r) => {
          return (
            <APIRequestRow
              key={r.id}
              apiRequest={r}
              showProject={showProject}
            />
          );
        })}
      </tbody>
    </Table>
  );
}

function SearchAPIRequests({
  baseSearch = null,
  showProject = true,
}: {
  baseSearch?: { agentId?: string; sessionId?: string } | null;
  showProject?: boolean;
}) {
  const [showResults, setShowResults] = useState<"all" | "only-errors">("all");
  const [projectId, setProjectId] = useState<string | null>(null);

  const search: SearchFilters = useMemo(() => {
    return { ...baseSearch, showResults, projectId };
  }, [baseSearch, showResults, projectId]);

  const [paginatedSearch, setPaginatedSearch] = useState<
    PaginatedSearch<SearchFilters>
  >({
    ...search,
    startsAfter: null,
  });
  const [isFirstPage, setIsFirstPage] = useState(true);

  const {
    isPending,
    isError,
    data: apiRequests,
    error,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: apiRequestQueries.search(paginatedSearch),
    queryFn: () => fetchAPIRequests(paginatedSearch),
  });

  let result: React.ReactElement;
  if (isPending) {
    result = <Spinner />;
  } else if (isError) {
    result = (
      <Alert variant="warning">
        Could not load API requests: {error.message}.
      </Alert>
    );
  } else {
    result = (
      <APIRequestsTable apiRequests={apiRequests} showProject={showProject} />
    );
  }

  return (
    <>
      <Row className="mb-2">
        <Col>
          <ButtonGroup>
            <ToggleButton
              type="radio"
              variant="outline-secondary"
              checked={showResults === "all"}
              onChange={
                // @ts-ignore
                (e) => setShowResults(e.currentTarget.value)
              }
              id="show-result-all"
              value="all"
            >
              All
            </ToggleButton>
            <ToggleButton
              type="radio"
              variant="outline-secondary"
              checked={showResults === "only-errors"}
              onChange={
                // @ts-ignore
                (e) => setShowResults(e.currentTarget.value)
              }
              id="show-result-only-errors"
              value="only-errors"
            >
              Only errors
            </ToggleButton>
          </ButtonGroup>
        </Col>
        <Col>
          {showProject && (
            <ProjectSelect projectId={projectId} onChange={setProjectId} />
          )}
        </Col>
        <Col xs={1}>
          <Button
            onClick={() => refetch()}
            disabled={!isFirstPage || isRefetching}
            title="Refresh"
          >
            {isRefetching && <Spinner size="sm" />}
            {!isRefetching && <i className="bi-arrow-clockwise" />}
          </Button>
        </Col>
      </Row>

      {result}

      <Paginator
        search={search}
        currentPage={apiRequests || null}
        onChangePaginatedSearch={({ paginatedSearch, isFirstPage }) => {
          setPaginatedSearch(paginatedSearch);
          setIsFirstPage(isFirstPage);
        }}
      />
    </>
  );
}

export default SearchAPIRequests;

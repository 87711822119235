import type {
  APIKey,
  APIRequest,
  APIRequestSummary,
  Organization,
  Page,
  Project,
  SessionDetails,
  SessionSummary,
  User,
} from "./types";

function buildQueryString(obj: Record<string, any>) {
  return Object.keys(obj)
    .map((k) => {
      const v = obj[k];
      const encodedValue =
        v !== null && v !== undefined ? encodeURIComponent(v) : "";
      return `${encodeURIComponent(k)}=${encodedValue}`;
    })
    .join("&");
}

export async function getAPI(url: string): Promise<any> {
  return fetch(url).then((res) => {
    if (res.status === 200) return res.json();
    else throw new Error(`Unexpected response status ${res.status}`);
  });
}

export async function postAPI(url: string, body: any): Promise<any> {
  return fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  }).then((res) => {
    if (res.status === 200) return res.json();
    else throw new Error(`Unexpected response status ${res.status}`);
  });
}

export async function fetchOrganization(): Promise<Organization> {
  return getAPI("/api/organization");
}

export async function fetchProjects(): Promise<Page<Project>> {
  return getAPI("/api/projects");
}

export async function fetchUsers(): Promise<Page<User>> {
  return getAPI("/api/users");
}

export async function fetchAPIKeys(): Promise<Page<APIKey>> {
  return getAPI("/api/api-keys");
}

export async function fetchAPIRequests({
  startsAfter = null,
  showResults = "all",
  projectId = null,
  agentId,
  sessionId,
}: {
  startsAfter: string | null;
  showResults: "all" | "only-errors";
  projectId: string | null;
  agentId?: string;
  sessionId?: string;
}): Promise<Page<APIRequestSummary>> {
  const onlyFailed = showResults === "only-errors";

  return getAPI(
    "/api/api-requests?" +
      buildQueryString({
        starting_after: startsAfter,
        only_failed: onlyFailed,
        project_id: projectId,
        agent_id: agentId,
        session_id: sessionId,
      }),
  );
}

export async function fetchAPIRequest(id: string): Promise<APIRequest> {
  return getAPI(`/api/api-requests/${id}`);
}

export async function fetchSessions({
  startsAfter = null,
  projectId = null,
}: {
  startsAfter: string | null;
  projectId: string | null;
}): Promise<Page<SessionSummary>> {
  return getAPI(
    "/api/sessions?" +
      buildQueryString({ starting_after: startsAfter, project_id: projectId }),
  );
}

export function fetchSession(sessionId: string): Promise<SessionDetails> {
  return getAPI(`/api/sessions/${sessionId}`);
}

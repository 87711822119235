import { useState } from "react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Alert, Button, Form, Spinner, Table } from "react-bootstrap";

import type { Page, Project } from "../api/types";
import { fetchProjects, postAPI } from "../api";

const projectQueries = {
  all: () => ["projects"],
};

type NewProject = {
  name: string;
};

function NewProjectRow() {
  const [name, setName] = useState<string>("");

  const queryClient = useQueryClient();
  const createProject = useMutation({
    mutationFn: (newProject: NewProject) =>
      postAPI(`/api/projects`, newProject),
    onSuccess: (p) => {
      setName("");

      queryClient.setQueryData<Page<Project>>(
        projectQueries.all(),
        (projects) =>
          projects && {
            ...projects!,
            items: [...projects!.items, p],
          },
      );
    },
  });

  return (
    <tr>
      <td />
      <td>
        <Form.Control
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="name"
        />
      </td>

      <td>
        <Button
          variant="light"
          onClick={() => createProject.mutate({ name })}
          disabled={createProject.isPending || !name}
        >
          {createProject.isPending ? <Spinner size="sm" /> : "➕"}
        </Button>
      </td>
    </tr>
  );
}

function ProjectsPage() {
  const {
    isPending,
    isError,
    data: projects,
    error,
  } = useQuery({
    queryKey: projectQueries.all(),
    queryFn: fetchProjects,
  });

  if (isPending) {
    return <div>Loading...</div>;
  } else if (isError) {
    return (
      <Alert variant="warning">Could not load projects: {error.message}.</Alert>
    );
  } else {
    return (
      <>
        <h2>Projects</h2>

        <p>
          Projects allow you to organize your account to make it easier to find
          & attribute activity.
        </p>

        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {projects.items.map((p) => {
              return (
                <tr key={p.id}>
                  <td>
                    <code>{p.id}</code>
                  </td>
                  <td>{p.name}</td>
                </tr>
              );
            })}
            <NewProjectRow />
          </tbody>
        </Table>
      </>
    );
  }
}

export default ProjectsPage;

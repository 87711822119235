import { useMemo, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { Alert, Button, Col, Row, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";

import type { Page, SessionSummary } from "../api/types";
import { fetchSessions } from "../api";
import ProjectSelect from "../projects/ProjectSelect";
import { PaginatedSearch, Paginator } from "../utils/pagination";

type SearchFilters = {
  projectId: string | null;
};

const sessionQueries = {
  search: (filters: PaginatedSearch<SearchFilters>) => [
    "sessions",
    "search",
    filters,
  ],
};

function DumpMetadata({ metadata }: { metadata: Record<string, string> }) {
  return (
    <ul style={{ listStyleType: "none", paddingLeft: 0 }} className="mb-0">
      {Object.keys(metadata).map((key) => {
        return (
          <li key={key}>
            <i>{key}</i>: {metadata[key]}
          </li>
        );
      })}
    </ul>
  );
}

function SessionRow({ session }: { session: SessionSummary }) {
  return (
    <tr>
      <td>
        <ReactTimeAgo
          date={new Date(session.created * 1000)}
          timeStyle="round"
        />
      </td>
      <td>
        <Link to={`/sessions/${session.id}`}>
          <code>{session.id}</code>
        </Link>
      </td>
      <td>{session.project_name}</td>
      <td>
        <DumpMetadata metadata={session.metadata} />
      </td>
    </tr>
  );
}

function SessionsTable({ sessions }: { sessions: Page<SessionSummary> }) {
  if (sessions.items.length === 0) {
    return <Alert>No matching sessions.</Alert>;
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Created</th>
          <th>Session</th>
          <th>Project</th>
          <th>Metadata</th>
        </tr>
      </thead>
      <tbody>
        {sessions.items.map((r) => {
          return <SessionRow key={r.id} session={r} />;
        })}
      </tbody>
    </Table>
  );
}

function SessionsLoader() {
  const [projectId, setProjectId] = useState<string | null>(null);

  const search: SearchFilters = useMemo(() => {
    return { projectId };
  }, [projectId]);

  const [isFirstPage, setIsFirstPage] = useState(true);
  const [paginatedSearch, setPaginatedSearch] = useState<
    PaginatedSearch<SearchFilters>
  >({
    ...search,
    startsAfter: null,
  });

  const { isPending, isError, data, error, refetch, isRefetching } = useQuery({
    queryKey: sessionQueries.search(paginatedSearch),
    queryFn: () => fetchSessions(paginatedSearch),
  });

  let result: React.ReactElement;
  if (isPending) {
    result = <Spinner />;
  } else if (isError) {
    result = (
      <Alert variant="warning">Could not load sessions: {error.message}.</Alert>
    );
  } else {
    result = <SessionsTable sessions={data} />;
  }

  return (
    <>
      <Row className="mb-2">
        <Col></Col>
        <Col>
          <ProjectSelect projectId={projectId} onChange={setProjectId} />
        </Col>
        <Col xs={1}>
          <Button
            onClick={() => refetch()}
            disabled={!isFirstPage || isRefetching}
            title="Refresh"
          >
            {isRefetching && <Spinner size="sm" />}
            {!isRefetching && <i className="bi-arrow-clockwise" />}
          </Button>
        </Col>
      </Row>

      {result}

      <Paginator
        search={search}
        currentPage={data || null}
        onChangePaginatedSearch={({ paginatedSearch, isFirstPage }) => {
          setPaginatedSearch(paginatedSearch);
          setIsFirstPage(isFirstPage);
        }}
      />
    </>
  );
}

function SessionsPage() {
  return (
    <>
      <h2>Sessions</h2>
      <SessionsLoader />
    </>
  );
}

export default SessionsPage;

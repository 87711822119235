import { useRef, useState } from "react";

import {
  arrow,
  autoUpdate,
  FloatingArrow,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import type { CurrentSession } from "../types";

function AvatarPopover({
  whoami,
  onLogOut,
}: {
  whoami: CurrentSession;
  onLogOut: () => void;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom-end",
    middleware: [offset(10), arrow({ element: arrowRef })],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  return (
    <>
      <div className="avatar" ref={refs.setReference} {...getReferenceProps()}>
        <img src={whoami.user.avatar_url} alt="" />
      </div>
      {isOpen && (
        <div
          className="avatar-popover"
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <FloatingArrow
            ref={arrowRef}
            context={context}
            fill="white"
            stroke="black"
            strokeWidth={1}
          />

          <p>{whoami.user.email}</p>

          <Link to="/organization" className="d-block">
            <i className="bi-building" /> My organization
          </Link>

          <Button
            variant="secondary"
            className="d-block mt-3"
            onClick={() => onLogOut()}
          >
            Log out
          </Button>
        </div>
      )}
    </>
  );
}

export default AvatarPopover;

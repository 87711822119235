import { Link, useOutletContext, useSearchParams } from "react-router-dom";

import type { SessionDetails } from "../api/types";
import SearchAPIRequests from "../api-requests/SearchAPIRequests";

function SessionAPIRequests() {
  const { session }: { session: SessionDetails } = useOutletContext();

  const [searchParams] = useSearchParams();
  const agentId = searchParams.get("agent_id");

  const baseSearch: { sessionId: string; agentId?: string } = {
    sessionId: session.id,
  };

  let explanation: React.ReactElement;

  if (agentId) {
    baseSearch["agentId"] = agentId;
    explanation = (
      <>
        <p>
          These are the API requests made for agent {agentId} within this
          session.
        </p>
        <p>
          <Link to="../api-requests">Show API requests for all agents</Link>
        </p>
      </>
    );
  } else {
    explanation = <p>These are the API requests made within this session.</p>;
  }

  return (
    <>
      <h3>API Requests</h3>

      {explanation}

      <SearchAPIRequests baseSearch={baseSearch} showProject={false} />
    </>
  );
}

export default SessionAPIRequests;

import { createBrowserRouter } from "react-router-dom";

import AgentsPage from "./agents/AgentsPage";
import APIKeysPage from "./api-keys/APIKeysPage";
import APIRequestPage from "./api-requests/APIRequestPage";
import APIRequestsPage from "./api-requests/APIRequestsPage";
import DashboardIndex from "./DashboardIndex";
import ErrorPage from "./ErrorPage";
import Layout from "./layout/";
import OrganizationPage from "./organization/OrganizationPage";
import ProjectsPage from "./projects/ProjectsPage";
import AgentEventStream from "./sessions/AgentEventStream";
import SessionAgents from "./sessions/SessionAgents";
import SessionAPIRequests from "./sessions/SessionAPIRequests";
import SessionPage from "./sessions/SessionPage";
import SessionsPage from "./sessions/SessionsPage";
import UsersPage from "./users/UsersPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <DashboardIndex />,
      },
      {
        path: "/organization",
        element: <OrganizationPage />,
      },
      {
        path: "/projects",
        element: <ProjectsPage />,
      },
      {
        path: "/sessions",
        element: <SessionsPage />,
      },
      {
        path: "/sessions/:sessionId",
        element: <SessionPage />,
        children: [
          {
            index: true,
            element: <SessionAgents />,
          },
          {
            path: "api-requests",
            element: <SessionAPIRequests />,
          },
        ],
      },
      {
        path: "/sessions/:sessionId/agent/:agentId/event-stream",
        element: <AgentEventStream />,
      },
      {
        path: "/agents",
        element: <AgentsPage />,
      },
      {
        path: "/users",
        element: <UsersPage />,
      },
      {
        path: "/api-keys",
        element: <APIKeysPage />,
      },
      {
        path: "/api-requests",
        element: <APIRequestsPage />,
      },
      {
        path: "/api-requests/:apiRequestId",
        element: <APIRequestPage />,
      },
    ],
  },
]);

export default router;
